<template>
  <div class="drafts">

    <myHeader :query="query" @search="searchChange"/>

    <!-- 一排按钮 -->
     <div class="buttonArr">
      <el-button :loading="deleteLoading" class="blueBoder" size="small" @click="batchDelete" v-p="['externaladmin:noticeList:drafts:batchDelete']">批量删除</el-button>
    </div>

     <!-- 表格 -->
     <CommonTable :tableLoading="tableLoading" height="auto" :cols="cols" :infoData="data" @selection-change="handleSelectionChange">
       <!-- 表格标题样式 -->
       <template #templateName="{scoped:row}">
         <div class="templateName" @click="showNotice(row)">{{row.title}}</div>
       </template>
      <!-- 操作列 -->
       <el-table-column fixed="right" label="操作" width="350px" align="center">
         <template slot-scope="{ row }">
            <color-text-btn class="update-btn"  size="mini" @click="issueNotice(row)" v-p="['externaladmin:noticeList:drafts:issueNotice']">发布</color-text-btn>
            <el-divider direction="vertical"></el-divider>
            <color-text-btn class="update-btn"  size="mini" @click="editContent(row)" v-p="['externaladmin:noticeList:drafts:editContent']">编辑</color-text-btn>
            <el-divider direction="vertical"></el-divider>
            <Popover @sureHandler="onDelete(row)">
                <template #tip>
                  <p>确定删除?</p>
                </template>
                <template #reference="{ scope: loading }">
                  <color-text-btn class="update-btn" :loading="row.deleteLoading" size="mini" type="danger" v-p="['externaladmin:noticeList:drafts:delete']">删除</color-text-btn>
                </template>
            </Popover>
         </template>
       </el-table-column>
    </CommonTable>

    <!-- 分页器 -->
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />

    <!-- 查看公告对话框 -->
    <el-dialog
      title="查看详情"
      :visible="noticeVisible"
      width="1000px"
      :before-close="noticehandleClose"
      class="articleWarpper"
    >
      <div class="articleContent" v-loading="noticeLoading">
      <!-- 标题 -->
      <div class="articleContent_title" v-html="articleData.title">
      </div>
      <!-- 类型 -->
      <div class="articleContent_line2">
      </div>
      <!-- 内容 -->
      <div class="articleContent_content" v-html="articleData.content">
      </div>

    </div>
     </el-dialog>
      <!-- 发布公告对话框 -->
    <el-dialog
      title="发布公告"
      :visible="submitNoticeVisible"
      width="800px"
      :before-close="submitHandleClose"
      class="noticeWarpper"
       @open="onopen"
    >
      <div class="noticeWarpper_content">
        <el-form :rules="sizeFormRule" ref="sizeForm" :model="sizeForm" label-width="100px" size="small">
          <el-row>
            <el-col :span="24">
              <el-form-item label="公告分类" prop="categoryId">
                <el-select clearable style="width: 240px" v-model="sizeForm.categoryId" placeholder="请选择公告分类">
                  <el-option v-for="v in noticeCate" :key="v.id" :label="v.categoryName" :value="v.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公告时间" prop="date">
                <span style="margin-right:20px;">开始时间</span>
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  v-model="sizeForm.date"
                  style="width: 204px"
                  :picker-options="pickerOptionsLimit"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="45px" prop="time">
                <el-time-picker
                  value-format="HH-mm-ss"
                  placeholder="选择时间"
                  v-model="sizeForm.time"
                  style="width: 160px"
                  :picker-options="pickerOptions"
                ></el-time-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="date2">
                <span style="margin-right:20px;">结束时间</span>
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  v-model="sizeForm.date2"
                  style="width: 204px"
                  :picker-options="pickerOptionsLimit"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="45px" prop="time2">
                <el-time-picker
                  value-format="HH-mm-ss"
                  placeholder="选择时间"
                  v-model="sizeForm.time2"
                  style="width: 160px"
                  :picker-options="endPickerOptions"
                ></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公告展示方式" prop="displayMode">
                <el-radio-group v-model="sizeForm.displayMode" size="medium">
                  <el-radio :label="1">单次弹出</el-radio>
                  <el-radio :label="2">每次登录弹出</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="submitHandleClose">取 消</el-button>
        <LoadingBtn size="small" type="primary" @click="submit">发 布</LoadingBtn>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {cols} from './cols'
import myHeader from './module/header.vue'
import { initDataMixin } from '@/mixins'
import { formatDate } from 'element-ui/src/utils/date-util'
import { noticeContentDetail,noticeContentDeleteToRecycleBin,noticeCategoryList,noticeContentUpdate } from '@/api/helpcenter/helpcenter'
export default {
   mixins: [initDataMixin],
  components:{
    myHeader
  },
  data(){
    return{
      url:'/externaladmin/systemService/noticeContentList',
      query:{
        status:1,
        orderItems: [{asc: false, column: "update_time"},{"asc":true,"column":"id"}]
      },
      cols,
        // 查看文章对话框显示控制articleVisible
      noticeVisible:false,
      articleData:{},
      deleteLoading:false,
      submitLoading:false,
      multiSelection:[],
       // 发布弹出框表单内容
       sizeForm: {
        categoryId: '',
        displayMode: 1,
        date: '',
        time: '',
        date2: '',
        time2: '',
        releaseTime: '',
        endTime: '',
      },
      sizeFormRule: {
        categoryId: [{ required: true, message: '请选择公告分类', trigger: 'blur' }],
        date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        date2: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        time2: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        displayMode: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      noticeLoading:false,
      submitNoticeVisible: false,
      submitId:'',
      noticeCate: [],
      pickerOptions: {
        selectableRange: null,
      },
       //日期限制
       pickerOptionsLimit: {
        disabledDate(time) {
          const date = formatDate(new Date(time), 'yyyy-MM-dd')
          const nowDate = formatDate(new Date(), 'yyyy-MM-dd')
          return (new Date(date)).getTime() < (new Date(nowDate)).getTime()
        }
      }
    }
  },

  computed: {
    startDateTime({ sizeForm: { date, time } }) {
      return date + ' ' + time.replace(/-/g, ':')
    },

    endDateTime({ sizeForm: { date2, time2 } }) {
      return date2 + ' ' + time2.replace(/-/g, ':')
    },

    startDateTimeStamp({ startDateTime }) {
      return +new Date(startDateTime)
    },

    endDateTimeStamp({ endDateTime }) {
      return +new Date(endDateTime)
    },

    endPickerOptions({sizeForm:{time}}) {
      return {
        selectableRange: `${time.replace(/-/g, ':')} - 23:59:59`
      }
    },

  },
  async created(){
    try {
      const { code, detail } = await noticeCategoryList({
        page: {
          pageSize: 0
        },
        orderItems: [
          { asc: true, column: 'sort_number' },
          { asc: true, column: 'id' }
        ]
      })
      if ($SUC({ code })) {
        this.noticeCate = detail
      } else {
        this.$message.error('获取分类失败')
      }
    } catch (e) {
      this.$message.error('获取分类失败')
    }
  },
  watch: {
    submitNoticeVisible: {
      handler(n) {
        if (n) {
          this.fillTime()
          this.endTime()
        }
      }
    }
  },
  methods:{
     // 将当前时间填入表单
    fillTime() {
      const today = new Date()
      this.sizeForm.date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const date = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date.getHours()
      const mm = date.getMinutes().toString().padStart(2, '0')
      const ss = date.getSeconds().toString().padStart(2, '0')
      this.sizeForm.time = `${hh}-${mm}-${ss}`
    },
    endTime() {
      const today = new Date()
      this.sizeForm.date2 = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const date2 = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date2.getHours()
      const mm = date2.getMinutes().toString().padStart(2, '0')
      const ss = date2.getSeconds().toString().padStart(2, '0')
      this.sizeForm.time2 = `${hh}-${mm}-${ss}`
    },
    selectableRange() {
      const today = new Date()
      const t = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const t1 = new Date(this.sizeForm.date)
      const t2 = new Date(t)
      console.log(today);
      console.log('t1-t2',t1-t2)
      if (t1 - t2 >= 86400000) return '00:00:00 - 23:59:59'
      const date = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date.getHours()
      const mm = date.getMinutes().toString().padStart(2, '0')
      const ss = date.getSeconds().toString().padStart(2, '0')
      return `${hh}:${mm}:${ss} - 23:59:59`
    },

    // 验证时间选择在5分钟之后,并且结束时间大于开始时间
    validateTime() {
      const { startDateTimeStamp, endDateTimeStamp } = this
      if (endDateTimeStamp <= startDateTimeStamp) {
        this.$message.error('结束时间必须大于开始时间，请重新设置后发布')
        return false
      }
      return true
    },

    // 表格勾选内容改变触发
    handleSelectionChange(val){
      this.multiSelection = val
    },
    // 删除
    async onDelete(row){
      const idList = []
      idList.push(row.id)
      try {
        this.$set(row,'deleteLoading',true)
        const {code} = await noticeContentDeleteToRecycleBin({idList})
         if ($SUC({ code })) {
           this.$message.success('删除公告成功')
           this.init()
          } else {
            this.$message.error('删除公告失败')
          }
        }catch(e){
          this.$message.error('删除公告失败')
        }finally{
          this.$set(row,'deleteLoading',false)
        }
    },
    // 批量删除
    async batchDelete(){
      if(this.multiSelection.length<1) return this.$message.warning('请先选择数据')
      const idList =[]
      this.multiSelection.map(item =>{
        idList.push(item.id)
      })
      try {
        this.deleteLoading = true
        const {code} = await noticeContentDeleteToRecycleBin({idList})
         if ($SUC({ code })) {
           this.$message.success('批量删除公告成功')
           this.init()
          } else {
            this.$message.error('批量删除公告失败')
          }
        }catch(e){
          this.$message.error('批量删除公告失败')
        }finally{
          this.deleteLoading = false
        }

    },
    editContent(row){
       this.$router.push({
        path:'/infoManage/createNotice',
        query:{
          id:row.id,
        }
      })
    },
    // 查看文章对话框关闭noticehandleClose
    noticehandleClose(){
        this.noticeVisible = false
    },
    // 查看文章对话框开启showNotice
    async showNotice(row){
      this.noticeVisible = true
      this.noticeLoading = true
      try{
        const {code,detail} = await noticeContentDetail({id:row.id})
        if($SUC({code})){
          this.articleData = detail
        }else{
          this.$message.error('获取数据失败')
        }
      }catch(e){
        this.$message.error('获取数据失败')
      }finally{
        this.noticeLoading = false
      }
    },
    issueNotice(row) {
          this.submitId = row.id
          this.submitNoticeVisible = true
    },
    submitHandleClose() {
      this.submitNoticeVisible = false
      this.$refs['sizeForm'].resetFields()
    },

    async submit() {
      if (!this.validateTime()) return
      try {
        await this.$refs['sizeForm'].validate()
      } catch (err) {
        return false
        }
        const {
        sizeForm: {
          categoryId,
          displayMode,
        },
        startDateTime: releaseTime,
        endDateTime: endTime,
      } = this
      try {
        const data = {
          categoryId,
          displayMode,
          releaseTime,
          endTime,
          status: 3,
          releaseType: 2,
          id:this.submitId,
        }
        const { code } = await noticeContentUpdate(data)
        if (!$SUC({ code })) return this.$message.error('发布失败')
        this.$message.success('发布成功')
        this.submitHandleClose()
        this.init()
      } catch (e) {
        this.$message.error('发布失败')
      } finally {
        this.submitLoading = false
      }
    },


    onopen() {
      this.pickerOptions.selectableRange = this.selectableRange()
     }

  }
}
</script>

<style lang="scss" scoped>
.drafts{
  .color-btn-component{
    font-size: 14px;
  }
  // 一排按钮
  .buttonArr{
    margin-bottom: 28px;
    .blueBoder{
      border-color: #3841DB;
      color: #3841DB;
    }
  }
  // 表格标题样式
  .templateName{
    color: #3841DB;
    cursor: pointer;
  }
   // 表格内编辑.primary
  .primary {
      color: #3841db !important;
      cursor: pointer;
    }
       // 查看文章对话框
  .articleWarpper{
    ::v-deep {
      .el-dialog__title {
        font-size: 16px;
        color: #495060;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
    }
    .articleContent{
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      // 标题
      .articleContent_title{
        margin: 16px 0 20px 0;
        color: #595961;
        font-size: 20px;
      }
      // 类型
      .articleContent_line2{
        display: flex;
        color: #A1A1A1;
        font-size: 14px;
        margin-bottom: 53px;
        .articleContent_line2_type{
          margin-right: 26px;
        }
      }
      // 内容
      .articleContent_content{
        width: 80%;
    }
  }
  }
   .noticeWarpper {
    ::v-deep {
      .el-dialog__title {
        font-size: 14px;
        color: #1a1a1a;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
    }
    .noticeWarpper_content {
      height: 400px;
      .el-form {
        .el-form-item {
          margin-bottom: 32px;
        }
        .last-item {
          display: flex;
          padding-left: 70px;
        }
        .datePickNotice {
          margin-top: -15px;
          padding-left: 170px;
          color: #595961;
        }
      }
    }

  }
}
</style>
